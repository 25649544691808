import { useEffect, useState, useContext, useCallback } from "react";
import { CONTRACT_ADDRESS } from "../contracts/config";
import abi from "ethereumjs-abi";
import { Button, Input, Text, VStack, HStack } from "@chakra-ui/react";
import { AppStateContext } from "./Context";
import * as api from "../api";

const constructPaymentMessage = (contractAddress, amount) => {
  return abi.soliditySHA3(["address", "uint256"], [contractAddress, amount]);
};

const User = () => {
  const [myBalance, setMyBalance] = useState();
  const [securityValue, setSecurityValue] = useState();
  const [balanceValue, setBalanceValue] = useState();
  const [signValue, setSignValue] = useState();
  const { web3, contract, account, setData } = useContext(AppStateContext);

  const etherToWei = (ether) => web3.utils.toWei(ether, "ether");
  const weiToEther = (wei) => web3.utils.fromWei(wei, "ether");

  const handleGetBalance = useCallback(async () => {
    const balance = await contract.methods
      .getUserBalance()
      .call({ from: account });

    setMyBalance({
      security: weiToEther(balance["1"]),
      balance: weiToEther(balance["0"]),
      refund: weiToEther(balance["2"]),
    });
  }, [account]);

  useEffect(() => {
    handleGetBalance();
  }, [handleGetBalance]);

  const signPayment = async (signer, contractAddress, amount) => {
    const message = constructPaymentMessage(contractAddress, amount);
    const signature = await web3.eth.personal.sign(
      "0x" + message.toString("hex"),
      signer
    );

    return { signature };
  };

  const handleSign = async () => {
    const amount = etherToWei(
      signValue, // converts Number to BN, which is accepted by `toWei()`
      "ether"
    );

    const { signature } = await signPayment(account, CONTRACT_ADDRESS, amount);

    const res = await api.signPayment({
      monthlyFeeId: "123456", // what's this?
      walletAddress: account,
      sign: signature,
    });

    console.log("API response", res);

    setData((prev) => [...prev, { amount, signature, signer: account }]);
    console.log("signature", signature);
  };

  const handleDepositSecurity = async () => {
    console.log(securityValue);
    const transaction = await contract.methods.depositSecurity().send({
      from: account,
      value: etherToWei(securityValue),
    });
    console.log("transaction:", transaction);
    handleGetBalance();

    const res = await api.initiateContract({
      contractId: '639d6c70812143ee57cef72f',
      walletAddress: account,
      contractHash: transaction.transactionHash,
    });

    console.log("API response", res);
  };

  const handleDepositBalance = async () => {
    console.log(balanceValue);
    const transaction = await contract.methods.depositBalance().send({
      from: account,
      value: etherToWei(balanceValue),
    });
    console.log("transaction:", transaction);
    handleGetBalance();
  };

  const handleWithdrawRefund = async () => {
    const transaction = await contract.methods
      .withdrawRefund()
      .send({ from: account });

    console.log("transaction:", transaction);
    handleGetBalance();
  };

  return (
    <VStack spacing="24px" align="flex-start">
      <VStack>
        <Text>Security: {myBalance?.security}</Text>
        <Text>Balance: {myBalance?.balance}</Text>
        <Text>Refund: {myBalance?.refund}</Text>
      </VStack>
      <Button onClick={handleGetBalance}>Get my balance</Button>
      <HStack>
        <Input
          value={securityValue}
          onChange={(e) => setSecurityValue(e.target.value)}
          placeholder="Amount in Ether"
        />
        <Button onClick={handleDepositSecurity}>Deposit Security</Button>
      </HStack>
      <HStack>
        <Input
          value={balanceValue}
          onChange={(e) => setBalanceValue(e.target.value)}
          placeholder="Amount in Ether"
        />
        <Button onClick={handleDepositBalance}>Deposit Balance</Button>
      </HStack>
      <HStack>
        <Input
          value={signValue}
          onChange={(e) => setSignValue(e.target.value)}
          placeholder="Amount in Ether"
        />
        <Button onClick={handleSign}>Sign</Button>
      </HStack>
      <Button onClick={handleWithdrawRefund}>Withdraw Refund</Button>
    </VStack>
  );
};

export default User;
