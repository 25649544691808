import { useEffect, useRef, useState } from "react";
import Web3 from "web3";
import {
  ADMIN_ADDRESS,
  CONTRACT_ABI,
  CONTRACT_ADDRESS,
} from "./contracts/config";
import { Button, Text, Container, VStack } from "@chakra-ui/react";
import { AppStateContext } from "./components/Context";
import Admin from "./components/Admin";
import User from "./components/User";

const App = () => {
  const [account, setAccount] = useState(); // state variable to set account.
  const [data, setData] = useState([]);

  const web3 = useRef(null);
  const contract = useRef(null);

  const handleConnect = async () => {
    const accounts = await web3.current.eth.requestAccounts();

    console.log(accounts);
    setAccount(accounts[0]);
  };

  const handleAccountsChanged = (accounts) => {
    // Time to reload your interface with accounts[0]!
    console.log("accountsChanged", accounts);
    setAccount(accounts[0]);
  };

  useEffect(() => {
    async function load() {
      // connect network
      const web3Instance = new Web3(
        Web3.givenProvider ||
          `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
      );
      web3.current = web3Instance;

      // connect contract
      contract.current = new web3Instance.eth.Contract(
        CONTRACT_ABI,
        CONTRACT_ADDRESS
      );

      if (!account) {
        handleConnect();
      }

      // detect accounts change in Metamask
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    load();

    return () => {
      window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
    };
  }, [account]);

  const isAdmin = account?.toUpperCase() === ADMIN_ADDRESS?.toUpperCase();

  return (
    <AppStateContext.Provider
      value={{
        web3: web3.current,
        contract: contract.current,
        account,
        data,
        setData,
      }}
    >
      {account ? (
        <Container>
          Your address:
          <Text>{account}</Text>
          {isAdmin ? <Admin /> : <User />}
        </Container>
      ) : (
        <VStack>
          <Text>Please connect your account first</Text>
          <Button onClick={handleConnect}>Connect Account</Button>
        </VStack>
      )}
    </AppStateContext.Provider>
  );
};

export default App;
