import React, { useContext, useState } from "react";

import {
  Button,
  VStack,
  UnorderedList,
  ListItem,
  Text,
  Input,
} from "@chakra-ui/react";
import { AppStateContext } from "./Context";

const Admin = () => {
  const { web3, contract, account, data, setData } =
    useContext(AppStateContext);
  const [signerValue, setSignerValue] = useState();
  const [securityValue, setSecurityValue] = useState();

  const etherToWei = (ether) => web3.utils.toWei(ether, "ether");
  const weiToEther = (wei) => web3.utils.fromWei(wei, "ether");
  const reduceSignatures = (sigs) =>
    sigs.reduce(
      (acc, item) => {
        acc.signers.push(item.signer);
        acc.amounts.push(item.amount);
        acc.signatures.push(item.signature);

        return acc;
      },
      {
        signers: [],
        amounts: [],
        signatures: [],
      }
    );

  const handleClaim = async () => {
    // claim single payments
    if (data.length === 1) {
      const { amount, signature, signer } = data[0];

      console.log(weiToEther(amount), signature);
      const transaction = await contract.methods
        .adminClaimPayment(signer, amount, signature)
        .send({ from: account });
      console.log(transaction);
    }

    // claim multiple payments
    if (data.length > 1) {
      const { signers, amounts, signatures } = reduceSignatures(data);

      console.log(signers, amounts, signatures);
      const transaction = await contract.methods
        .adminClaimPayments(signers, amounts, signatures)
        .send({ from: account });

      console.log(transaction);
    }
    // reset
    setData([]);
  };

  const handleTerminate = async () => {
    const { amounts, signatures } = reduceSignatures(data);

    console.log(signerValue, securityValue, amounts, signatures);

    const transaction = await contract.methods
      .adminTerminate(
        signerValue,
        etherToWei(securityValue),
        amounts,
        signatures
      )
      .send({ from: account });

    console.log("transaction:", transaction);
  };

  return (
    <VStack>
      <Text>Signatures:</Text>
      <UnorderedList>
        {data.map((item) => (
          <ListItem key={item.signature}>
            {item.signer.slice(-4)}: {weiToEther(item.amount)}ETH -{" "}
            {item.signature.slice(-4)}
          </ListItem>
        ))}
      </UnorderedList>
      <Button onClick={handleClaim}>Claim Payment</Button>
      <Text>Terminate Service</Text>
      <Input
        value={signerValue}
        onChange={(e) => setSignerValue(e.target.value)}
        placeholder="User Address"
      />
      <Input
        value={securityValue}
        onChange={(e) => setSecurityValue(e.target.value)}
        placeholder="Security in Ether"
      />
      <Button onClick={handleTerminate}>Terminate</Button>
    </VStack>
  );
};

export default Admin;
