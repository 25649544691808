import axios from "axios";
import md5 from "md5";

export const createSignature = (data) =>
  md5(process.env.REACT_APP_API_PRIVATE_KEY + data, "utf8").toLowerCase();

const instance = axios.create({
  baseURL: "http://cryptoadmin.testapi.taotaroapp.com/",
  transformRequest: [
    function (data, headers) {
      headers.signature = createSignature(data[data.signatureKey]);

      return data;
    },
  ],
});

export const initiateContract = (data) => {
  return instance.post("/contract/contract_webhook", {
    ...data,
    signatureKey: "contractId",
  });
};

export const signPayment = (data) => {
  return instance.post("/contract/authorize", {
    ...data,
    signatureKey: "monthlyFeeId",
  });
};

// crypto.createHash("md5").update("e2250122081447fc9c4d9d26954db4b1" +"639d6c70812143ee57cef72f","utf8").digest("hex").toLowerCase();
